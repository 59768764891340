const randomHex = require('random-hex-color')
const chroma = require('chroma-js')
const sample = require('lodash.sample')
const { randomInt } = require('../util')

const generateDiv = color => {
  color = color || randomHex()
  const secondColor = randomHex()

  const mode = sample(['lab', 'rgb', 'lrgb', 'hsl', 'lch'])

  const steps = randomInt(8, 11)

  const div = {
    canvasBg: '#ffffff',
    first: color,
    second: secondColor,
    mode: mode,
    steps: steps,
    paddingLeft: 4,
    paddingRight: 1
  }

  return {
    ...div
  }
}

module.exports = generateDiv
