/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import chroma from 'chroma-js'

import { Grid, ArrowRight, Heart, BookOpen } from 'react-feather'
import { randomInt } from '../lib/util'
import parseCombos from '../lib/parseCombos'

import Input from '../elements/Input'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomDiv from '../lib/generate/shadow-tint-scale'
import { db } from '../lib/client'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const Main = ({ div }) => {
  const colorScale = chroma
    .scale(['#000000', div.first, '#ffffff'])
    .padding([div.paddingLeft / 100, div.paddingRight / 100])
    .mode(div.mode)
    .correctLightness()
    .colors(div.steps)

  const combos = parseCombos(colorScale)

  return (
    <div
      sx={{
        minHeight: '16rem',
        height: '100%',
        overflowY: 'scroll',
        pt: 0,
        pl: 0,
        pr: 0,
        background: div.canvasBg,
        color: chroma.contrast(div.canvasBg, 'white') > 4.4 ? 'white' : 'black'
      }}
    >
      <div>
        <section>
          <div
            sx={{
              display: 'block',
              width: '100%',
              fontSize: 14
            }}
          >
            {colorScale.map((color, i) => (
              <article>
                <div
                  sx={{
                    display: 'flex',
                    background: color,
                    p: [3, 4, 4],
                    width: '100%'
                  }}
                >
                  <span
                    sx={{
                      fontSize: '1rem',
                      color:
                        chroma.contrast(color, 'black') > 4.49
                          ? 'black'
                          : 'white'
                    }}
                  >
                    {color}
                  </span>
                  <span
                    sx={{
                      ml: 'auto',
                      mr: 3,
                      width: '48px',
                      textAlign: 'right',
                      fontWeight:
                        chroma.contrast(color, 'white') > 4.49 ? 800 : 400,
                      color: 'white'
                    }}
                  >
                    {chroma.contrast(color, 'white').toFixed(2)}
                  </span>
                  <span
                    sx={{
                      fontWeight:
                        chroma.contrast(color, 'black') > 4.49 ? 800 : 400,
                      color: 'black',
                      width: '64px',
                      textAlign: 'right'
                    }}
                  >
                    {chroma.contrast(color, 'black').toFixed(2)}
                  </span>
                </div>
              </article>
            ))}
          </div>
        </section>
        <section sx={{ mt: 64, px: [3, 4, 4] }}>
          <h5>Accessible combinations</h5>
          <div sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {combos.map((combo, i) => (
              <div
                sx={{
                  p: 3,
                  color: combo.pairing,
                  bg: combo.hex,
                  width: 'auto'
                }}
              >
                <span
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    display: 'block',
                    mb: 2
                  }}
                >
                  {combo.contrast.toFixed(2)}
                </span>
                <div sx={{ display: 'flex' }}>
                  <div sx={{ pr: 24 }}>
                    <span sx={{ fontSize: 12, display: 'block' }}>Color</span>
                    <code sx={{ fontSize: 12, display: 'block' }}>
                      {combo.pairing}
                    </code>
                  </div>
                  <div>
                    <span sx={{ fontSize: 12, display: 'block' }}>
                      Background
                    </span>
                    <code sx={{ fontSize: 12, display: 'block' }}>
                      {combo.hex}
                    </code>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  )
}

const GridItem = ({ div, ...props }) => {
  const colorScale = chroma
    .scale(['#000000', div.first, '#ffffff'])
    .padding([div.paddingLeft / 100, div.paddingRight / 100])
    .mode(div.mode)
    .correctLightness()
    .colors(div.steps)

  return (
    <div
      sx={{ width: ['20%', '100%', '20%'], height: ['20%', '4%', '20%'], p: 2 }}
      {...props}
    >
      <a
        sx={{
          cursor: 'pointer',
          display: 'table',
          tableLayout: 'fixed',
          cellSpacing: 0,
          cellPadding: 0,
          border: 0,
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          transition: 'opacity .2s ease-out',
          opacity: 1,
          ':hover': {
            opacity: 0.5,
            transition: 'opacity .2s ease-in'
          }
        }}
      >
        {colorScale.map((color, i) => (
          <div
            sx={{
              display: 'table-cell',
              background: color,
              height: '100%',
              width: 'auto'
            }}
          ></div>
        ))}
      </a>
    </div>
  )
}

const GridView = ({ pins, onSelect }) => (
  <div
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      height: '100%',
      p: 2
    }}
  >
    {Array(25)
      .fill(0)
      .map((_, i) => {
        const baseDiv = randomDiv()
        const divWithPins = { ...baseDiv, ...pins }
        const div = {
          ...divWithPins
        }

        return <GridItem key={i} onClick={() => onSelect(div)} div={div} />
      })}
  </div>
)

const Sidebar = ({ onChange, value, pins, onPin }) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  const colorScale = chroma
    .scale(['#000000', value.first, '#ffffff'])
    .padding([value.paddingLeft / 100, value.paddingRight / 100])
    .mode(value.mode)
    .correctLightness()
    .colors(value.steps)

  const colorCssVars = colorScale
    .map((color, i) => {
      return `--color-${i * 1}: ${color};\n`
    })
    .join('')

  const colorJsVars = colorScale
    .map((color, i) => {
      return `"${color}",\n`
    })
    .join('')

  return (
    <>
      <SidebarHeading>Color Scale</SidebarHeading>
      <Color
        label="Canvas"
        value={value.canvasBg}
        onChange={changeValue('canvasBg')}
        active={pins.canvasBg}
        onLock={() => onPin('canvasBg', value.canvasBg)}
        width={128}
      />
      <Color
        label="Color"
        value={value.first}
        onChange={changeValue('first')}
        active={pins.first}
        onLock={() => onPin('first', value.first)}
        width={128}
      />
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.mode}
          onClick={() => onPin('mode', value.mode)}
        />
        <Label>Scale Mode</Label>
        <select value={value.mode} onChange={changeValue('mode')}>
          <option>lab</option>
          <option>hsl</option>
          <option>lch</option>
          <option>lrgb</option>
          <option>rgb</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.steps}
          onClick={() => onPin('paddingLeft', value.paddingLeft)}
        />
        <Label>Padding Left</Label>
        <Input
          min={-200}
          max={200}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.paddingLeft)}
          type="range"
          onChange={changeValue('paddingLeft')}
        />
        <Input
          min={-200}
          max={200}
          steps={1}
          width={96}
          value={Number(value.paddingLeft)}
          type="number"
          onChange={changeValue('paddingLeft')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.steps}
          onClick={() => onPin('paddingRight', value.paddingRight)}
        />
        <Label>Padding Right</Label>
        <Input
          min={-200}
          max={200}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.paddingRight)}
          type="range"
          onChange={changeValue('paddingRight')}
        />
        <Input
          min={-200}
          max={200}
          steps={1}
          width={96}
          value={Number(value.paddingRight)}
          type="number"
          onChange={changeValue('paddingRight')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.steps}
          onClick={() => onPin('steps', value.steps)}
        />
        <Label>Steps</Label>
        <Input
          min={0}
          max={12}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.steps)}
          type="range"
          onChange={changeValue('steps')}
        />
        <Input
          min={0}
          steps={1}
          width={96}
          value={Number(value.steps)}
          type="number"
          onChange={changeValue('steps')}
        />
      </Flex>
      <SectionBorder my={3} />
      <h5 sx={{ mb: 1 }}>
        <span sx={{ fontSize: 0 }}>Css Variables</span>
      </h5>
      <textarea
        sx={{
          bg: 'transparent',
          height: 128,
          width: '100%',
          border: '1px solid rgba(0,0,0,.25)',
          p: 2,
          fontSize: 0
        }}
        readOnly
        value={`root: {\n` + colorCssVars + '\n' + '}'}
      />
      <h5 sx={{ mb: 1 }}>
        <span sx={{ fontSize: 0 }}>Js</span>
      </h5>
      <textarea
        sx={{
          bg: 'transparent',
          height: 128,
          width: '100%',
          border: '1px solid rgba(0,0,0,.25)',
          p: 2,
          fontSize: 0
        }}
        readOnly
        value={`{\n` + `"colors": [\n` + colorJsVars + '\n' + `],\n` + '}'}
      />
    </>
  )
}

export default ({ initialDiv, initialPins }) => {
  let votes = []
  try {
    votes = JSON.parse(localStorage.getItem('textImageVotes')) || []
  } catch (e) {}

  const [div, setDiv] = useState(initialDiv || randomDiv())
  const [pins, setPins] = useState(initialPins || {})
  const [gridView, setGridView] = useState(false)

  const divWithPins = { ...div, ...pins }
  const fullDiv = {
    ...divWithPins
  }

  const upvote = async () => {
    if (gridView) {
      return
    }

    const { id } = await db
      .collection('components')
      .doc('textImages')
      .collection('votes')
      .add({
        up: true,
        data: fullDiv,
        metadata: { pins }
      })

    localStorage.setItem(
      'textImagesVotes',
      JSON.stringify(
        [
          {
            id
            //backgroundColor: fullGradient.backgroundColor,
            //backgroundImage: fullGradient.backgroundImage
          },
          ...votes
        ]
          .slice(0, 100)
          .filter(Boolean)
      )
    )

    setDiv(randomDiv())
  }
  const skip = () => {
    setDiv(randomDiv())
  }
  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  const handleGridSelection = div => {
    setDiv(div)
    setGridView(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        gridView ? (
          <GridView pins={pins} onSelect={handleGridSelection} />
        ) : (
          <Main div={fullDiv} />
        )
      }
      sidebar={
        <Sidebar
          value={fullDiv}
          pins={pins}
          onChange={(key, value) => {
            setDiv({
              ...div,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <div
            sx={{
              color: 'black',
              textAlign: 'center',
              display: 'flex',
              width: '100%',
              alignItems: 'center'
            }}
          >
            {false && (
              <Link
                to="/div/votes"
                sx={{
                  color: 'black',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textDecoration: 'none'
                }}
              >
                <BookOpen size={20} />
                <span sx={{ paddingLeft: 10 }}>Previous likes</span>
              </Link>
            )}
          </div>
          <Flex>
            <VoteButton width="50%" title="Next" onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
            <VoteButton width="50%" title="Grid View" onClick={toggleGridView}>
              <Grid size={20} />
              <Hidden>Grid view</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
